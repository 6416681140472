
const PartySize = () =>{

    const partySize = ["1 person", "2 people", "3 people", "4 people", "5 people", "6 people",
                        "7 people", "8 people", "9 people", "10 people", "11 people", "12 people",
                        "13 people", "14 people", "15 people","16 people", "17 people", "18 people",
                        "19 people", "20 people"]
    return partySize;
}

export default PartySize;